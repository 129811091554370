








































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import scheduleModule from '@/store/modules/scheduleModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import cellRender from './CellRender.js';
// import CellRender from './CellRender.js';
import CustomHeader from './header.js';
import EditableHeader from './headerEditable.js';
import TagSelect from './userTagCell.vue';
import DateSelect from './userDateCell.vue';
import UserSelect from './userSelectCell.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    agColumnEdiable: EditableHeader,
    UserSelect,
    Multiselect,
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    TagSelect,
    DateSelect,
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   newColumnName = '';

   newGroupName = '';

   showModal = false;

  showCreateGroupModal =false;

  activeDeleteRowWellName= '';

  deleteRowPopup = false;

  selectedWellNames = [];

  hideAgGrid: any = false;

  availableWellNames = [];

  dataLoading = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags = ['Tag1', 'Tag2']

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   selectedWell: any = ''

   columnDefs: any = [

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    cellClick(e) {
      console.log(e);
    }

    get isMobile() {
      return this.windowWidth < 769;
    }

    goToTasqPage(data) {
      const { colDef } = data;
      const wellName = data.value;
      if (colDef && colDef.field === 'Well') {
        const tasq = tasqsListModule.tasqList.find((t) => t.wellName === wellName);
        if (tasq && tasq.id) {
          tasqsListModule.setActiveTasq(tasq.id || '');
          tasqsListModule.setKanbanSelectedTasq(tasq?.id || '');
          this.showModal = true;
        } else {
          tasqsListModule.setKanbanSelectedTasq(wellName);
          assetsModule.setActiveProducingTasq(wellName);
          tasqsListModule.setActiveTasq('');
          this.showModal = true;
        }
      }
    // tasqsListModule.setActiveTasq(tasq?.id || '');
    }

    addTag(newTag) {
      if (newTag) {
        this.availableTags.push(newTag);
        this.selectedTags.push(newTag);
      }
    }

    async stopEdit() {
      await this.saveGroupDetailsToBackend();
    }

    closeDialog() {
      this.hideAgGrid = true;
      console.log('close');
      this.showModal = false;
      setTimeout(() => {
        this.hideAgGrid = false;
      }, 300);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = [];
      this.columnDefs = [];
      this.gridColumnApi = params.columnApi;
      const columns = [];
      this.gridApi.hideOverlay();

      if (this.activeGroup && this.activeGroup.Payload) {
        this.rowData = this.activeGroup.Payload;
      }

      if (this.activeGroup && this.activeGroup.Columns) {
        this.activeGroup.Columns.forEach((col) => {
          // console.log(col.ColumnType);
          const hideColumn = false;
          let newCol: any = null;
          // if(col.CustomName === ''){
          //   col.CustomName = 'Unnamed'
          // }
          if (col.ColumnType === 'WELL') {
            newCol = {
              field: 'Well',
              headerName: col.CustomName,
              cellClass: 'noborder',
              cellStyle: { border: 'none' },
              sortable: true,
              filter: true,
              width: 300,
              editable: false,
              checkboxSelection: false,
            };
            this.columnDefs.push(newCol);
          } else if (col.ColumnType === 'TAGS') {
            newCol = {
              field: col.ColumnName,
              sortable: true,
              editable: hideColumn,
              headerComponent: 'agColumnEdiable',
              cellClass: 'noborder',
              cellStyle: {
                border: 'none', overflow: 'visible', height: '200px', display: 'block',
              },
              headerName: col.CustomName,
              suppressRowTransform: true,
              suppressToolPanel: true,
              colId: col.ColumnName,
              initialWidth: 350,
              cellRendererFramework: 'TagSelect',
            };
            this.columnDefs.push(newCol);
          } else if (col.ColumnType === 'DATE') {
            newCol = {
              field: col.ColumnName,
              sortable: true,
              editable: false,
              headerComponent: 'agColumnEdiable',
              cellClass: 'noborder',
              cellStyle: { border: 'none', overflow: 'visible' },
              headerName: col.CustomName,
              suppressRowTransform: true,
              suppressToolPanel: true,

              colId: col.ColumnName,
              initialWidth: 150,
              cellRendererFramework: 'DateSelect',
            };
            this.columnDefs.push(newCol);
          } else if (col.ColumnType === 'NUMBERS') {
            newCol = {
              field: col.ColumnName,
              headerComponent: 'agColumnEdiable',
              colId: col.ColumnName,
              initialWidth: 150,
              cellStyle: { border: 'none' },
              cellClass: 'noborder',
              headerName: col.CustomName,
              // valueParser: function numberParser(value) {
              //   // isNaN(value.newValue)
              //   return Number.isNaN(value.newValue) ? '' : Number(value.newValue);
              // },

              valueGetter(value) {
                const key = col.ColumnName;
                return value.data[key];
              },

              valueSetter: (value) => {
                if (isNaN(value.newValue)) {
                  // alert('not valid number');
                  return false;
                }
                const key = col.ColumnName;
                value.data[key] = value.newValue;
                return true;
              },

              // valueParser:  function numberFormatter(value) {
              //   console.log(Number.isNaN(parseInt(value.newValue)))
              //   return isNaN(Number(value.value)) ? '': (Number(params.value))
              // },
            };
            this.columnDefs.push(newCol);
          } else {
            newCol = {
              field: col.ColumnName,
              headerComponent: 'agColumnEdiable',
              colId: col.ColumnName,
              cellStyle: { border: 'none', overflow: 'visible' },
              cellClass: 'noborder',
              headerName: col.CustomName,
            };
            this.columnDefs.push(newCol);
          }
        });

        const endColumn = {
          field: 'Add Column +',
          sortable: true,
          pinned: 'right',
          cellClass: 'noborder',
          suppressToolPanel: true,
          cellStyle: { border: 'none' },
          headerComponent: 'agColumnHeader1',
          editable: false,

          initialWidth: 120,
          cellRendererFramework: 'cellRender',
          cellRendererParams: {
            clicked(field) {
              alert(`${field} was clicked`);
            },
          },
        };

        this.columnDefs.push(endColumn);
      }
    }

    createGroup() {
      this.selectedTags = [];
      this.selectedWellNames = [];
      this.newGroupName = '';
      this.showCreateGroupModal = false;
    }

    async confirmDeleteRow() {
      this.deleteRowPopup = false;
      this.rowData = this.rowData.filter((d) => d.Well !== this.activeDeleteRowWellName || !d.Well);
      await this.saveGroupDetailsToBackend();
      this.activeDeleteRowWellName = '';
    }

    removeRow(wellName) {
      this.activeDeleteRowWellName = wellName;
      this.deleteRowPopup = true;
    }

    async methodFromParent(name, type) {
      let count = 0;
      const isExist = this.columnDefs.filter((d) => d.field.includes(type));

      if (isExist) {
        count = isExist.length + 1;
      }
      const updatedName = name + count;

      if (name && type && type === 'Tags') {
        const data = {
          field: name.toUpperCase(),
          sortable: true,
          editable: false,
          headerComponent: 'agColumnEdiable',
          cellClass: 'noborder',
          cellStyle: { border: 'none' },
          headerName: name,

          colId: name,
          initialWidth: 350,
          cellRendererFramework: 'TagSelect',
        };
        this.columnDefs.push(data);
      } else if (name && type && type === 'Date') {
        const data = {
          field: `${updatedName}`,
          sortable: true,
          editable: false,
          headerComponent: 'agColumnEdiable',
          cellClass: 'noborder',
          cellStyle: { border: 'none' },
          headerName: `${name}`,
          colId: `${updatedName}`,
          initialWidth: 160,
          cellRendererFramework: 'DateSelect',

        };

        this.columnDefs.push(data);
      } else if (name && type && type === 'Numbers') {
        this.columnDefs.push({
          field: `${updatedName}`,
          headerComponent: 'agColumnEdiable',
          cellStyle: { border: 'none' },
          colId: `${updatedName}`,
          initialWidth: 160,
          cellClass: 'noborder',
          headerName: name,
          valueParser: function numberParser(value) {
            return Number.isNaN(value.newValue) ? '' : Number(value.newValue);
          },
        });
      } else {
        this.columnDefs.push({
          field: `${updatedName}`,
          headerComponent: 'agColumnEdiable',
          cellStyle: { border: 'none' },
          colId: `${updatedName}`,
          initialWidth: 160,
          cellClass: 'noborder',
          headerName: name,
        });
      }

      this.activeGroup.Columns.push({
        ColumnName: `${updatedName}`,
        ColumnType: name.toUpperCase(),
        CustomName: name,
      });

      this.gridApi.hideOverlay();
      await this.saveGroupDetailsToBackend();
    }

    async saveGroupDetailsToBackend() {
      const updatedObject = {
        group_id: this.activeGroup.ID,
        columns: JSON.stringify(this.activeGroup.Columns),
        payload: JSON.stringify(this.rowData),
      };

      await scheduleModule.updateGroupDetails(updatedObject);
    }

    beforeMount() {
      this.context = { componentParent: this };
      // @ts-ignore
      window.gridApiVue = this;
    }

    closeColumnDialog() {
      this.showColumnMenu = false;
    }

    get assignedWells() {
      return this.rowData.map((element) => element.Well);
    }

    get enabledWells() {
      console.log(this.assignedWells);
      console.log(assetsModule.enabledWells);
      return assetsModule.enabledWells.filter((w) => !this.assignedWells.includes(w.wellName));
    }

    get activeGroup() {
      return scheduleModule.activeGroup;
    }

    async updateColumnName(oldColName, newColumnName) {
      // console.log(oldColName);
      // console.log(this.activeGroup.Columns);
      this.activeGroup.Columns = this.activeGroup.Columns.map((c) => {
        if (c.CustomName === oldColName) {
          c.CustomName = newColumnName;
        }
        return c;
      });

      this.columnDefs = this.columnDefs.map((c) => {
        if (c.headerName === oldColName) {
          c.headerName = newColumnName;
        }
        return c;
      });
      // console.log(this.activeGroup.Columns);
      await this.saveGroupDetailsToBackend();
    }

    async updateDataFromCell(index, fieldName, data) {
      this.rowData[index][fieldName] = data;
      // console.log( this.rowData[index][fieldName])
      await this.saveGroupDetailsToBackend();
    }

    async created() {
      this.dataLoading = true;
      this.getRowNodeId = (data) => data.Well;
      await assetsModule.getEnabledWells();
      // this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);
      if (!this.activeGroup) {
        this.$router.push({ name: 'Groups' });
      }
      await this.sleep(500);
      this.dataLoading = false;
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

    async removeColumn(columnName) {
      this.columnDefs = this.columnDefs.filter((d) => d.headerName !== columnName);
      this.activeGroup.Columns = this.activeGroup.Columns.filter((d) => d.CustomName !== columnName);
      await this.saveGroupDetailsToBackend();
    }

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async addRow() {
      if (this.newWellName) {
        this.rowData.push({ Well: this.newWellName });
        this.newWellName = '';
      }
      await this.saveGroupDetailsToBackend();
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }

    @Watch('columnDefs')
    updateRowData(data) {
      if (data) {
        if (data.length > 1 && data[1].field === 'Add Column +' && data[1].pinned === 'left') {
          this.columnDefs[1].pinned = 'right';
        }
      }
    }
}

