<template>
  <div>
    <date-range-picker
      ref="picker"
      v-model="customSignalRange"
      append-to-body
      :single-date-picker="true"
      :ranges="false"
      :locale-data="{ firstDay: 1, format: 'mm-dd-yy' }"
      opens="right"
      @update="customDateUpdated"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components: {
    Multiselect,
    DateRangePicker,
  },

  data() {
    return {
      customSignalRange: { startDate: null, endDate: null },
    };
  },
  beforeMount() {
    if (this.params.value) {
      // this.selectUser = this.params.value;
      this.customSignalRange.startDate = (this.params.value)
      this.customSignalRange.endDate = (this.params.value)
    }
  },

  methods: {

    isPopup() {
      return true;
    },
    customDateUpdated(newTag) {
      console.log(newTag);
      console.log(this.params.value);

      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      console.log(this.params);
      this.value = this.customSignalRange.startDate;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      console.log(rowNode)
       this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, this.value);
      // rowNode.setDataValue(fieldName, this.value);
      this.params.api.refreshCells();

      //  var rowNode = this.gridApi.getRowNode('bb');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>;

<style scoped>
.customUI >>> .multiselect__tags {
    background: #283751;
    border-color: transparent;
    padding: 4px;
}

.customUI >>> .multiselect__select {
  display: none;
}

.customUI >>> .multiselect__tag {
  background: rgb(61,153,170);
}

.customUI >>> .multiselect__content-wrapper{
  position: fixed;
}

.customUI >>> .multiselect__option--highlight {
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__tag-icon:after{
  background:rgb(61,153,170);
}

.customUI >>> i.multiselect__tag-icon{
  background:rgb(61,153,170);
}

.customUI >>> input {
  background: #283751;
  color: white;
}

.customUI >>> .multiselect__option--highlight:after{
  background:rgb(61,153,170);
}
.customUI >>> .multiselect__content-wrapper {
  position: fixed;
  width: 300px;
  display: none;
}

.vue-daterange-picker >>> .reportrange-text {
  background: transparent;
  border: none;
  width: 150px;
  height: 35px;
}
</style>
